











































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

import moment from 'moment'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError', 'filter',
      'report_data', 'merchants', 'cities', 'merchant_categories',
      'filering', 'filter_ranges', 'brands',
    ]),
    ...mapGetters(STORE_KEY, [
      'summary',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
      'loadMerchants', 'loadCities', 'loadMerchantCategories',
      'loadMerchantsFilterRanges',
      'resetFilterState',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ReportBonusStats extends Vue {

  public showModal: any = false
  public showModalNotifications: any = false
  public filter!: any
  public checkedRows: any[] = []
  public dropFiles: any = null
  public message: string = ''
  public title: string = ''
  public send_datetime: any = moment().toDate()
  public sending: boolean = false

  get titleStack() {
    return [
      'Статистика по начисленным бонусам',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  public sendNotification() {
    if (!this.message || !this.send_datetime) {
      // nothing
    }
    else {
      this.sending = true
      this.$store.dispatch(`${STORE_KEY}/sendNotification`, {
        title: this.title,
        message: this.message,
        send_datetime: this.send_datetime,
        file: this.dropFiles,
        users: this.checkedRows.map((item: any) => {
          return item.users
        }).flat(),
      }).then(() => {
        this.sending = false
        this.message = ''
        this.send_datetime = moment().toDate()
        this.dropFiles = null
        this.showModalNotifications = false
        this.$buefy.snackbar.open(`Рассылка выполнена!`)
      }).catch(() => {
        this.sending = false
      })
    }
  }

  public uploadFile(files: any) {
    const formData = new FormData()

    formData.append('file', files)

    // @ts-ignore
    this.$http
      .post(`/admins/rest/v1/uploads`, formData, {})
      .then((response: any) => {
        this.dropFiles = response.data.upload
      })

    // // @ts-ignore
    // this.$v.form.promotion.image_upload_id.$touch();
  }

  // ---------------------------------------------------------------------------


  public setFilter() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
    this.showModal = false
  }

  // ---------------------------------------------------------------------------


  public resetFilter() {
    this.$store.dispatch(`${STORE_KEY}/resetFilterState`)
    this.showModal = false
    this.handleParamsChange()
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantsFilterRanges`)
    this.$store.dispatch(`${STORE_KEY}/loadCities`)
    this.$store.dispatch(`${STORE_KEY}/loadBrands`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantCategories`)
  }

  // ---------------------------------------------------------------------------

  public created() {
    // console.log(this.$store._modules.root._children)
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
