import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'
import { BNavItem } from 'bootstrap-vue'

interface ReportByChannelState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  report_data: any[];
  cities: any[];
  brands: any[];
  merchant_categories: any[];
  filter: any;
  filter_ranges: any;
}

const $state: ReportByChannelState = {
  loading: false,
  loaded: false,
  fatalError: false,

  report_data: [],
  cities: [],
  brands: [],
  merchant_categories: [],
  filter: {
    brand: null,
    city: null,
    merchant_category: null,
    registration_type: '',
    outlets_count: [0, 0],
    receipts_count: [0, 0],
    receipts_sum: [0, 0],
    feedbacks_count: [0, 0],
    marks_count: [0, 0],
    common_mark: [0, 5],
    disсouted_bonuses: [0, 0],
    accural_bonuses: [0, 0],
    balance: [0, 0],
    date_from: null,
    date_to: null,
  },
  filter_ranges: null,
}

export const $actions: ActionTree<ReportByChannelState, RootState> = {
  loadData({ commit, getters, dispatch }, filter) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}
      if (filter) {
        params = {
          cities: filter.cities ? JSON.stringify(filter.cities.map((item: any) => item.id)) : null,
          brands: filter.brands ? JSON.stringify(filter.brands.map((item: any) => item.id)) : null,
          categories: filter.merchant_categories ? JSON.stringify(filter.merchant_categories.map((item: any) => item.id)) : null,
          registration_type: filter.registration_type,
          outlets_count_min: filter.outlets_count[0],
          outlets_count_max: filter.outlets_count[1],
          receipts_count_min: filter.receipts_count[0],
          receipts_count_max: filter.receipts_count[1],
          receipts_sum_min: filter.receipts_sum[0] * 100,
          receipts_sum_max: filter.receipts_sum[1] * 100,
          feedbacks_count_min: filter.feedbacks_count[0],
          feedbacks_count_max: filter.feedbacks_count[1],
          marks_count_min: filter.marks_count[0],
          marks_count_max: filter.marks_count[1],
          common_mark_min: filter.common_mark[0],
          common_mark_max: filter.common_mark[1],
          disсouted_bonuses_min: filter.disсouted_bonuses[0],
          disсouted_bonuses_max: filter.disсouted_bonuses[1],
          accural_bonuses_min: filter.accural_bonuses[0],
          accural_bonuses_max: filter.accural_bonuses[1],
          balance_min: filter.balance[0],
          balance_max: filter.balance[1],
          date_from: filter.date_from,
          date_to: filter.date_to,
        }
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/reports/bonus_stats', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  loadMerchantsFilterRanges({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchants/filter_ranges`)
        .then((response: AxiosResponse) => {
          commit('SET_FILTER_RANGES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
  loadCities({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadBrands({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/brands`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_BRANDS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadMerchantCategories({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchant_categories`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANT_CATEGORIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  resetFilterState({ commit, dispatch }, predefined) {
    commit('SET_FILTER_DEFAULTS')
    // dispatch(`loadData`)
  },
  sendNotification({ state }, params) {
    console.log(params)
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      const notification: any = {
        title: params.title,
        message: params.message,
        send_datetime: params.send_datetime,
        type: 'system',
      }
      notification.file_id = params.file ? params.file.id : null
      notification.promotion_id = params.promotion ? params.promotion.id : null
      notification.users = [...new Set(params.users)]
      vm.$http
        .post(`/admins/rest/v1/notifications`, { notification })
        .then((response: AxiosResponse) => {
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          reject(error.response?.data)
        })
    })
  },

}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<ReportByChannelState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEMS_LIST(state, data) {
    const report_data: any = {}

    data.data.map((item: any) => {
      if (!report_data[item.brand_id]) {
        report_data[item.brand_id] = {
          brand_id: item.brand_id,
          answered_percent: item.answered_percent,
          balance: item.balance,
          bonuses_count: item.bonuses_count || 0,
          brand_name: item.brand_name,
          categories: [item.categories],
          common_mark: item.common_mark,
          conversion_percent: item.conversion_percent,
          discounted_sum: item.discounted_sum,
          feedbacks_count: item.feedbacks_count,
          last_bonus: item.last_bonus ? moment(item.last_bonus) : null,
          mailing_count: item.mailing_count,
          marks_count: item.marks_count,
          name: item.name,
          outlets_count: item.outlets_count,
          paid_sum: item.paid_sum,
          receipts_count: item.receipts_count,
          receipts_sum: item.receipts_sum,
          subscribers_count: item.subscribers_count,
          users: item.users,
          items: [item],
        }
      }
      else {
        report_data[item.brand_id].items.push(item)
        report_data[item.brand_id].answered_percent += item.answered_percent
        report_data[item.brand_id].balance += item.balance
        report_data[item.brand_id].bonuses_count += item.bonuses_count
        report_data[item.brand_id].categories.push(item.categories)
        report_data[item.brand_id].common_mark += item.common_mark
        report_data[item.brand_id].conversion_percent += item.conversion_percent
        report_data[item.brand_id].discounted_sum += item.discounted_sum
        report_data[item.brand_id].feedbacks_count += item.feedbacks_count
        report_data[item.brand_id].last_bonus = item.last_bonus ? moment.max(moment(item.last_bonus), report_data[item.brand_id].last_bonus) : report_data[item.brand_id].last_bonus
        report_data[item.brand_id].mailing_count += item.mailing_count
        report_data[item.brand_id].marks_count += item.marks_count
        report_data[item.brand_id].name = item.name
        report_data[item.brand_id].outlets_count += item.outlets_count
        report_data[item.brand_id].paid_sum += item.paid_sum
        report_data[item.brand_id].receipts_count += item.receipts_count
        report_data[item.brand_id].receipts_sum += item.receipts_sum
        report_data[item.brand_id].subscribers_count += item.subscribers_count
        report_data[item.brand_id].users.concat(item.users)
      }
      report_data[item.brand_id].answered_percent = report_data[item.brand_id].answered_percent / report_data[item.brand_id].items.length
      report_data[item.brand_id].common_mark = report_data[item.brand_id].common_mark / report_data[item.brand_id].items.length
      report_data[item.brand_id].conversion_percent = report_data[item.brand_id].conversion_percent / report_data[item.brand_id].items.length
    })
    state.report_data = Object.values(report_data)
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_MERCHANT_CATEGORIES: (state, { merchant_categories }) => {
    state.merchant_categories = merchant_categories.sort(compare)
  },
  SET_BRANDS: (state, { brands }) => {
    state.brands = brands.sort(compare)
  },
  SET_FILTER_RANGES: (state, { filter_ranges }) => {
    state.filter.outlets_count = [0, filter_ranges.outlets_count + 0]
    state.filter.receipts_count = [0, filter_ranges.receipts_count + 0]
    state.filter.receipts_sum = [0, filter_ranges.receipts_max / 100]
    state.filter.feedbacks_count = [0, filter_ranges.feedbacks_count + 0]
    state.filter.marks_count = [0, filter_ranges.marks_count + 0]
    state.filter_ranges = Object.assign({}, filter_ranges)
  },
  SET_FILTER_DEFAULTS: (state) => {
    state.filter = {
      city_id: null,
      merchant_category_id: null,
      registration_type: 'all',
      outlets_count: [0, 0],
      receipts_count: [0, 0],
      receipts_sum: [0, 0],
      feedbacks_count: [0, 0],
      marks_count: [0, 0],
      common_mark: [0, 5],
      disсouted_bonuses: [0, 0],
      accural_bonuses: [0, 0],
      balance: [0, 0],
      date_from: null,
      date_to: null,
    }
    state.filter_ranges = null
  },
}

export const $getters:
  GetterTree<ReportByChannelState, RootState> = {
  summary(state) {
    const sum = {
      outlets_count: 0,
      receipts_count: 0,
      receipts_sum: 0,
      feedbacks_count: 0,
      answered_percent: 0,
      marks_count: 0,
      common_mark: 0,
      mailing_count: 0,
      subscribers_count: 0,
      conversion_percent: 0,
      bonuses_count: 0,
      last_bonus: '',
      discounted_sum: 0,
      paid_sum: 0,
      balance: 0,
    }
    state.report_data.forEach((obj) => {
      sum.outlets_count += obj.outlets_count
      sum.receipts_count += obj.receipts_count
      sum.receipts_sum += obj.receipts_sum / 100
      sum.feedbacks_count += obj.feedbacks_count
      sum.answered_percent += obj.answered_percent
      sum.marks_count += obj.marks_count
      sum.common_mark += obj.common_mark
      sum.mailing_count += obj.mailing_count
      sum.subscribers_count += obj.subscribers_count
      sum.conversion_percent += obj.conversion_percent
      sum.bonuses_count += obj.bonuses_count
      sum.discounted_sum += obj.discounted_sum
      sum.paid_sum += obj.paid_sum
      sum.balance += obj.balance
    })
    sum.answered_percent = sum.answered_percent / state.report_data.length
    sum.common_mark = sum.common_mark / state.report_data.length
    return sum
  },
}

export const STORE_KEY = '$_report_by_bonuses'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
